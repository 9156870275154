import React, { useState, useEffect,useRef } from 'react'; 
import { Chart } from "react-google-charts";
import {useMsal}from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import {Formik,Form} from "formik";
import * as Yup from "yup";
import { Helmet } from 'react-helmet';
import {InteractionRequiredAuthError} from '@azure/msal-browser';

var username="";
var headers = {'Authorization':'none','Authcode':'none','Authstring':'none'};
var tableName='';
var queryDisplayField='';

export const options = {
  allowHtml: true,
  showRowNumber: true,
};
let xData={"Test1":"TestData"};
var tableData="";
function pushItems(values){
 const valuesContents = Object.values(values);
 tableData.push(valuesContents);
}

function logwithTokens(instance,account,logData){
	instance.acquireTokenSilent({scopes: ["https://gnsgvoportal.accenture.com/GVO.Role"],account: account}).then(tokenResponse => {
	        const requestOptions = {
        	        method: 'POST',
                	headers: { 'Content-Type': 'application/json','Authorization': tokenResponse.accessToken },
                	body: JSON.stringify({'data': logData})
        	};
		fetch('https://gnsgvo.accenture.com/api/log',requestOptions);
	}).catch(error => {
	
	});
}

function fetchTokens(headers,setData,setProgress){
        setData('[["loading"],["Please wait while your data is loaded"]]')
        fetch('https://gnsgvo.accenture.com/api/get',{headers})
        .then(response=> response.text())
        .then(tokendata => {
           try {xData=JSON.parse(tokendata)
           xData.forEach(pushItems)
           setProgress(100);
           setData(tableData)
	   } catch (err) {
		setProgress(100);
		switch (tableName){
	        case 'DID':
			pushItems({"PID":"Error","Account#":"Error","Region":"Error","Number":"Error","CountryCode":"Error","Type":"Error","Status":"Error","ExtractedUTC":"Error Occured...try again"})
	                setData(tableData)
			break;
 		case 'Users':
			pushItems({"PID":"Error","Account#":"Error","UserId":"Error","Region":"Error","PhoneType":"Error","FirstName":"Error","LastName":"Error","ExtractedUTC":"Error Occured...try again"})
	                setData(tableData)
			break;
		case 'Accounts':
			pushItems({"PID":"Error","AccountNumber":"Error","AdminURL":"Error","RegionName":"Error","UserCount":"0","DREnabled":"No","ExtractedUTC":"Error Occured...try again"})
	                setData(tableData)
			break;
		case 'TFN':
			pushItems({"PID":"Error","Provider":"Error","TFN":"Error","Country":"Error","Type":"Error","Owner":"Error","Primary DID":"Error","Secondary DID":"Error","ExtractedUTC":"Error Occured...try again"})
	                setData(tableData)
			break;
		case 'Bills':
			pushItems({"PID":"Error","Account#":"Error","Region":"Error","Country":"Error","Facility":"Error","Unit":"Error","ISMT":"Error","Connectivity":"Error","Profile":"Error","Seats":"Error","ExtractedUTC":"Error Occured...try again"})
			setData(tableData)
	                break;
		}
	   }   
        });
}


function getTokens(instance,account,setData,setProgress){
        setData('[["loading"],["Please wait while your data is loaded"]]')
	instance.acquireTokenSilent({scopes: ["https://gnsgvoportal.accenture.com/GVO.Role"],account: account}).then(tokenResponse => {
		headers['Authorization']=tokenResponse.accessToken;
		fetchTokens(headers,setData,setProgress);
	}).catch(error => {
	    if (error instanceof InteractionRequiredAuthError) {
		    return instance.acquireTokenRedirect({scopes: ["User.Read"],account: account})
	    }
});}


export function Backend() {
  var displayDataType='';
  const [reactData, setData] = useState([]);
  const [errorMSG,setErrorMsg]=useState([]);
  const [progress, setProgress] = useState(0);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const searchInput = useRef(null);
  const searchSubInput = useRef(null);
  const location = useLocation();
  useEffect(() => {
        setData(tableData)
        setErrorMsg("");
        searchSubInput.current.value='';
  },[location.state?.resource]);

  const searchProjectStart =()=>{
	  	const GDCNHolder=searchInput.current.value;
	  	setProgress(null);
	        document.getElementsByName("ProgressForm")[0].style.visibility = "visible";
		schemaQuery.validate({PID:GDCNHolder }).then(values => {
		headers['Authstring']='PID:'+GDCNHolder;
//		logwithTokens(instance,activeAccount,{'PID': activeAccount.name+'_'+headers['Authcode']+'_'+GDCNHolder});
		setErrorMsg("");
	  	getTokens(instance,activeAccount,setData,setProgress);}).catch(error => {
						setErrorMsg("   Invalid search Input:- "+error);
						setProgress(100);
						});
  } 

  const searchSubStart = () => {
	const GDCNHolder=searchSubInput.current.value;
	setProgress(null);
	document.getElementsByName("ProgressForm")[0].style.visibility = "visible";
	const tempStr='{"'+tableName+'":"'+GDCNHolder+'"}';
        const comparatorMsg=JSON.parse(tempStr);
	schemaQuery.validate(comparatorMsg).then(values => {
	headers['Authstring']='nonPID:'+GDCNHolder;
//	logwithTokens(instance,activeAccount,{'nonPID': activeAccount.name+'_'+tableName+'_'+GDCNHolder});
	setErrorMsg("");
	getTokens(instance,activeAccount,setData,setProgress);}).catch(error => {
		setErrorMsg("   Invalid search Input:- "+error);
		setProgress(100);
		});
  }

  if (activeAccount){
               username=activeAccount.name;
  } else {
     return(<h3> This page is under construction  </h3>)
  }
  tableName=location.state?.resource;
  switch (tableName){
        case 'DID':
                displayDataType='Phone Numbers';
		queryDisplayField='Phone Number';
                tableData=[['PID', 'Account#', 'Region', 'Number', 'CountryCode', 'Type', 'Status', 'ExtractedUTC']];
		headers['Authcode']='DIDs';
		break;
        case 'Users':
		displayDataType='Users';
	        queryDisplayField='Enterprise ID';
                tableData=[['PID', 'Account#', 'UserId', 'Region', 'PhoneType', 'FirstName', 'LastName', 'ExtractedUTC']];
		headers['Authcode']='Users';
                break;
        case 'Accounts':
		displayDataType='Accounts';
		queryDisplayField='Account# (last 4 digits)';
                tableData=[['PID','Account#','AdminURL','Region','# Users','DR Available','ExtractedUTC']];
		headers['Authcode']='Accounts';
		break;
	case 'TFN':
		displayDataType='Toll Free Numbers';
		queryDisplayField='TFN Number';
		tableData=[['PID','Provider','TFN','Country','Type','Owner','Primary DID','Secondary DID','ExtractedUTC']];
		headers['Authcode']='TFNs';
		break;
	case 'Bills':
	       displayDataType='Seats Countrywise';
	       queryDisplayField='Country Name';
               tableData=[['PID','Account#','Region','Country','Facility','Unit','ISMT','Connectivity','Profile','Seats','ExtractedUTC']];
               headers['Authcode']='Bills';
		break;
	case 'Services':
		displayDataType='Page Build Not completed, coming soon...';
		break;
	case 'Features':
		displayDataType='Page Build Not completed, coming soon...';
		break;
	case 'Survey':
		displayDataType='Page Build Not completed, coming soon...';
		break;
	default:
		displayDataType='Page Build Not compleyed, coming soon...';
		break;
	 }


  const schemaQuery = Yup.object().shape({
	PID: Yup.string().max(6,"must be max 6 characters").min(4,"must be 6 characters").matches(/^[nN]+[0-9]+$/,"enter in GDCN ID format"),
        DID: Yup.string().max(15,"must be 15 characters").min(4,"must be 6 characters").matches(/^[+1-9]+[0-9]+$/,"Enter in Phone Number format"),
	Users:Yup.string().max(20,"must be less than 20 characters").min(6,"must be atleast 6 characters").matches(/^[a-zA-Z]+[a-zA-Z.]+$/,"must be Enterprise ID"),
	Accounts:Yup.string().max(4,"specify upto 4 digits only").min(3,"must be atleast 3 digits").matches(/^[0-9]+$/,"Enter last 4 digits"),
	TFN:Yup.string().min(4,"specify atleast 4 digits").max(15,"Phone numbers must be max 15 digits").matches(/^[+1-9]+[0-9]+$/,"Enter in Phone Number format"),
	Bills:Yup.string().max(20,"specify only upto 20 characters").matches(/^[a-zA-Z]+$/,"Enter Country Name"),
	Services:Yup.string().max(20,"specify only upto 20 characters").matches(/^[a-zA-Z]+$/,"Will be developed"),
	Features:Yup.string().max(20,"specify only upto 20 characters").matches(/^[a-zA-Z]+$/,"Will be developed"),
	Survey:Yup.string().max(20,"specify only upto 20 characters").matches(/^[a-zA-Z]+$/,"Will be developed")
  });


  return (
      <div> 
	<Helmet>
	  <meta name="robots" content="noindex" />
	</Helmet>
	 <Formik initialValues={{SearchInput:""}} 
	   validationSchema={Yup.object({ 
		SearchInput: Yup.string().max(6,"must be 6 characters").min(4,"must be 6 characters").matches(/^[n]+[0-9]+$/),
	  })}
	  onSubmit={(values,{setSubmitting}) => {
		searchProjectStart();
		setSubmitting(false);
	  }}
	  >
	  <Form>
        <h3>{displayDataType} </h3>
	 <label>Search Project: </label>
	  <input
            name="SearchInput"
            type="text"
            placeholder="GDCN ID"
            id="SearchInput"
	    ref = {searchInput}
          />
	  <button onClick={searchProjectStart} type="button" >Search</button>
	 <label > or {displayDataType}: </label>
	  <input
	   name="SearchUser"
	   type="test"
	   placeholder={queryDisplayField}
	   id="SearchUser"
	   ref = {searchSubInput}
	 />
	  <button onClick={searchSubStart} type="button">Search</button>
	  <label id="ErrorLabel" 
	   style={{color:"red"}}
	  >{errorMSG}</label>
          <progress value={progress} max="100" id="ProgressForm" name="ProgressForm" style={{ marginLeft: "1.5rem", visibility:"hidden" }} />
	  <Chart
           chartType="Table"
           width="100%"
           height="400px"
           data={reactData}
           options={options}
        />
	 </Form>
	</Formik>
     </div> 
  );

}
